import styled from 'styled-components';
import overlayIllustration from 'assets/illustrations/overlay.svg';
import intro from 'assets/images/intro_bg.png';

export const Wrapper = styled.div`
  padding-bottom: 0;
  // background-image: url(${overlayIllustration});
  background-size: contain;
  background-position: right top;
  background-repeat: no-repeat;
  // background-position: top right;
  // background-image:url(${intro}); 
`;

export const IntroWrapper = styled.div`
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: -10em;

  @media (max-width: 960px) {
    flex-direction: column;
    margin-top: 0;
  }

  @media (max-width: 1024px) {
    margin-top: -9em;
  }

  @media (max-width: 768px) {
    margin-top: 0;
  }

  @media (max-width: 680px) {
    margin-top: 0;
  }
`;

export const Details = styled.div`
  flex: 1;
  order: 1;

  @media (max-width: 960px) {
    width: 100%;
    margin-bottom: 0;
    order: 2;
  }

  h1 {
    margin-bottom: 2rem;
    margin-top: 2rem;
    font-size: 1.5em;
    color: ${({ theme }) => (theme === 'light' ? '#c7c7c7' : '#c7c7c7')};

    @media (max-width: 960px) {
      mix-blend-mode: ${({ theme }) => (theme === 'light' ? 'unset' : 'difference')};
    }

    @media (max-width: 680px) {
      font-size: 1.5em;
    }
  }

  h4 {
    margin-bottom: 2rem;
    font-size: 32pt;
    font-weight: normal;
    color: ${({ theme }) => (theme === 'light' ? '#707070' : '#c7c7c7')};

    @media (max-width: 960px) {
      mix-blend-mode: ${({ theme }) => (theme === 'light' ? 'unset' : 'difference')};
    }

    @media (max-width: 680px) {
      font-size: 26pt;
    }
  }
`;

export const Thumbnail = styled.div`
  flex: 1;
  order: 2;

  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    margin-bottom: 2rem;
  }

  img {
    width: 100%;
  }
`;

export const Kereszty = styled.div`
  color: #000000;
  font-size: 5em;
  font-weight: 800;
  @media (max-width: 960px) {
    font-size: 3em;
  }
`;

export const Praxis = styled.div`
  color: #c4996c;
  font-size: 4em;
  @media (max-width: 960px) {
    margin-bottom: 1em;
    font-size: 1.5em;
  }
  font-weight: 888;
  margin-top: -0.7em;
  margin-left: 4em;
`;

export const newPraxis = styled.div`
  font-size: 1.5em;
`;

export const Tel = styled.div`
  font-size: 1.5em;
  a {
    color: #c7c7c7;
  }
  @media (max-width: 680px) {
    font-size: 1em;
    text-align: center;
  }
  @media (max-width: 960px) {
    font-size: 1.5em;
    text-align: center;
  }
`;

export const Email = styled.div`
  font-size: 1.5em;
  a {
    color: #c7c7c7;
  }
  @media (max-width: 680px) {
    font-size: 1.5em;
    text-align: center;
  }
  @media (max-width: 960px) {
    font-size: 1.5em;
    text-align: center;
  }
`;

export const IntroDrTitle = styled.div`
  @media (max-width: 960px) {
    text-align: center;
  }
  color: #c7c7c7;
`;

export const IntroDrSubtitle = styled.div`
  @media (max-width: 960px) {
    text-align: center;
  }
  color: #c7c7c7;
`;

export const Behandlung = styled.div`
  color: #c7c7c7;
  a {
    color: #c7c7c7;
  }
  ul {
    list-style: none;
  }
  li {
    display: inline;
    margin-inline-end: 0s;
  }
  h3 {
    font-size: 1em;
    margin-top: 1.5em;
    margin-bottom: 0.5em;
  }
  @media (max-width: 960px) {
    text-align: center;
  }
`;

export const Address = styled.div`
  font-size: 1.5em;
  a {
    color: #c7c7c7;
  }
  @media (max-width: 960px) {
    text-align: center;
    font-size: 1.5em;
  }
`;

export const InsideWrapper = styled.div``;

export const ContactImageMapsWrapper = styled.div`
  flex: 1;
  order: 1;
  display: flex;
  flex-direction: row;
  margin-top: 2em;

  @media (max-width: 960px) {
    width: 100%;
    margin-bottom: 0;
    order: 2;
    flex-direction: column;
    margin-top: 0;
  }
`;

export const ContactImageWrapper = styled.div`
  .gatsby-image-wrapper {
    width: 30vw;
    @media (max-width: 960px) {
      width: 100vw;
    }
  }
`;

export const ContactMapsWrapper = styled.div`
  width: 70vw;
  @media (max-width: 960px) {
    width: 100vw;
    margin-top: 1em;
  }
`;
