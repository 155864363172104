/* eslint-disable react-hooks/rules-of-hooks */
import React, { useContext } from 'react';
import { ThemeContext } from 'providers/ThemeProvider';
import { Header } from 'components/theme';
import { Container } from 'components/common';
import { useIntl, Link, FormattedMessage } from 'gatsby-plugin-intl';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { ContactImage } from 'components/theme/ContactImage';
import { ContactMaps } from 'components/theme/ContactMaps';

import {
  Wrapper,
  IntroWrapper,
  Details,
  Thumbnail,
  Tel,
  Email,
  IntroDrTitle,
  IntroDrSubtitle,
  Behandlung,
  Address,
  ContactImageMapsWrapper,
  ContactImageWrapper,
  ContactMapsWrapper,
} from './styles';

export const Intro = () => {
  const { theme } = useContext(ThemeContext);

  return (
    <StaticQuery
      query={graphql`
        query IntroQuery {
          file(relativePath: { eq: "intro_bg.png" }) {
            childImageSharp {
              # Specify the image processing specifications right in the query.
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `}
      render={data => (
        <Wrapper>
          <Header />
          <IntroWrapper as={Container}>
            <Thumbnail>
              <Img fluid={data.file.childImageSharp.fluid} alt="dr. Kereszty Praxis, dentalmikroskop" />
              <IntroDrTitle>
                <FormattedMessage id="intro.dr.title" />
              </IntroDrTitle>
              <IntroDrSubtitle>
                <FormattedMessage id="intro.dr.subtitle" />
              </IntroDrSubtitle>
            </Thumbnail>
            <Details theme={theme}>
              <Address>
                <a
                  href="https://www.google.com/maps/place/Sopron,+V%C3%A1rker%C3%BClet+27,+9400/@47.687427,16.5902863,17z/data=!3m1!4b1!4m5!3m4!1s0x476c3ca22cfca5ed:0x677114a49b4e9451!8m2!3d47.687427!4d16.592475"
                  title={useIntl().formatMessage({ id: 'seo.title' })}
                  target="blank"
                >
                  Sopron, Várkerület 27.
                </a>
              </Address>
              <Tel>
                <a href="tel:+36706111427">+36 70 6111427</a>
              </Tel>
              <Email>
                <a href="mailto:office@dental-profi.eu">office@dental-profi.eu</a>
              </Email>
              <Behandlung>
                <h3>
                  <FormattedMessage id="footer.behandlungtitle" />
                </h3>
                <ul>
                  <li>
                    <Link to="/implantat">
                      <FormattedMessage id="footer.behandlung.1" />
                    </Link>{' '}
                    /{' '}
                  </li>
                  <li>
                    <Link to="/krone">
                      <FormattedMessage id="footer.behandlung.2" />
                    </Link>{' '}
                    /{' '}
                  </li>
                  <li>
                    <Link to="/digitezaleahnheilkunde">
                      <FormattedMessage id="footer.behandlung.4" />
                    </Link>{' '}
                    /{' '}
                  </li>
                  <li>
                    <Link to="/dentalmikroskop">
                      <FormattedMessage id="footer.behandlung.5" />
                    </Link>
                  </li>
                </ul>
              </Behandlung>
            </Details>
          </IntroWrapper>
          <ContactImageMapsWrapper>
            <ContactImageWrapper>
              <ContactImage />
            </ContactImageWrapper>
            <ContactMapsWrapper>
              <ContactMaps />
            </ContactMapsWrapper>
          </ContactImageMapsWrapper>
        </Wrapper>
      )}
    />
  );
};
