import React from 'react';
import { Layout, SEO } from 'components/common';
import { Intro, Skills, Contact, Projects } from 'components/landing';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl"
import { Works } from 'components/landing/Works';

export default () => (
  <Layout>
    <SEO />
    <Intro />
  </Layout>
);
